<template>
    <b-list-group>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-list-group-item variant="secondary"><b-skeleton></b-skeleton></b-list-group-item>
                <b-list-group-item><b-skeleton></b-skeleton></b-list-group-item>
                <b-list-group-item><b-skeleton></b-skeleton></b-list-group-item>
                <b-list-group-item><b-skeleton></b-skeleton></b-list-group-item>
            </template>
            <b-list-group-item v-if="error.length > 0" variant="danger">
                <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
                {{ error }}
            </b-list-group-item>
            <b-list-group-item v-else-if="title"></b-list-group-item>
            <b-list-group-item
                v-for="(item, idx) in items"
                :key="idx"
            >
                <slot name="default" :items="items" :item="item" :index="idx"></slot>
            </b-list-group-item>
        </b-skeleton-wrapper>
    </b-list-group>
</template>
<script>

export default {
    props: [
        "title",
        "src"
    ],
    mounted() {
        this.loadItems();
    },
    data() {
        return {
            loading : false,
            error   : "",
            items   : []
        };
    },
    methods: {
        loadItems() {
            this.loading = true;
            this.error   = "";
            this.$api.get(this.src).then(({ data }) => {
                if (data.success)
                    this.items = data.records;
                else
                    this.error = data.message;
                this.loading = false;
            });
        }
    }
}
</script>
