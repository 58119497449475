<template>
    <div>
        <generic-list
            :src="`/api/forms/admin/${form_id}/response-changes/${response_id}`"
        >
            <template #default="{ item, items, index }">
                <b>
                    Changed by {{ item.person.first_name }} {{ item.person.last_name }}
                    on {{ $moment(item.date_changed).format("LLLL") }}
                </b>
                <table class="table table-sm w-100">
                    <tr><th>Field</th><th>Old Value</th><th>Changed Value</th></tr>
                    <tr>
                        <td>{{ field_labels[item.changed_field] }}</td>
                        <td>{{ item.old_value }}</td>
                        <td>
                            {{ (index > 0) ? items[index - 1].old_value : (response[item.changed_field] || "") }}
                        </td>
                    </tr>
                </table>
            </template>
        </generic-list>
    </div>
</template>
<script>
import GenericList from '@/components/GenericList.vue'

export default {
    props: ["form_id", "response_id", "form", "response"], /* TODO: Make a standalone view at some time */
    components: { GenericList },
    mounted() {
        this.field_labels = Object.fromEntries(
            this.form.fields.map(
                (field) => [field.id, field.label]
            )
        );
    },
    data() {
        return {
            field_labels: {}
        };
    }
}
</script>