<template>
    <div>
        <b-navbar variant="light">
            <b-navbar-nav>
                <b-nav-item
                    @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                >
                    &larr; Back
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-brand
                :to="{ name: 'get_form_response_sheet' }"
                class="m-auto"
            >
                <font-awesome-icon
                    icon="table"
                    class="mr-2"
                ></font-awesome-icon>
                View responses
            </b-navbar-brand>
        </b-navbar>
        <FormResponseNavigator
            v-model="responseCount"
            :responses="responses"
        />
        <div class="text-center my-3">
            <button
                class="sc-btn pill mr-2"
                @click="refresh()"
            >
                <font-awesome-icon icon="sync"></font-awesome-icon>
                Refresh
            </button>
            <button
                v-if="responses.length > 0"
                class="sc-btn pill"
                @click="exportCSV()"
            >
                <font-awesome-icon icon="download"></font-awesome-icon>
                Export CSV
            </button>
        </div>
        <template v-if="responses.length > 0">
            <b-alert
                v-if="currentResponse && currentResponse.removed"
                :show="currentResponse.removed"
                variant="danger"
                class="mt-2"
            >
                <font-awesome-icon icon="exclamation-triangle" class="mr-2"></font-awesome-icon>
                Response has been removed.
            </b-alert>
            <template v-else>
                <b-alert
                    v-if="currentResponse"
                    :show="currentResponse.changed"
                    variant="danger"
                    class="mt-2"
                >
                    <font-awesome-icon icon="exclamation-triangle" class="mr-2"></font-awesome-icon>
                    Response has been changed since previous submission.
                </b-alert>
                <b-card>
                    <template v-if="currentResponse && currentResponse.person">
                        Submitted by: {{ currentResponse.person.first_name }} {{ currentResponse.person.last_name }}
                    </template>
                    <template v-else>
                        Submitted by: Anonymous
                    </template>
                    &bullet; {{ $moment(currentResponse.date_submitted).format("LLLL") }}

                    <template #footer>
                        <b-link
                            v-for="(action, idx) in responseActions"
                            :key="`action-${idx}`"
                            class="card-link"
                            @click="actionChange(action.value)"
                        >
                            <font-awesome-icon
                                :icon="action.icon"
                                class="mr-1"
                            />
                            {{ action.text }}
                        </b-link>
                    </template>
                </b-card>
                <form-response-changes
                    v-if="selectedAction == 'view_changes'"
                    :key="'response' + responseCount"
                    :form_id="form.id"
                    :response_id="currentResponse.id"
                    :form="form"
                    :response="currentResponse.response"
                    class="mt-2"
                >
                </form-response-changes>
                <Form
                    v-else
                    :key="'response-form-' + responseCount"
                    :fields="fields"
                    :prefill="currentResponse.response"
                    :disabled="selectedAction != 'edit'"
                    :submit="true"
                    @onSubmit="saveRowChange"
                ></Form>
            </template>
        </template>
    </div>
</template>
<script>
import Form                from "@/components/form/Form.vue";
import FormResponseChanges from "./FormResponseChanges.vue";
import FormResponseNavigator from "./components/FormResponseNavigator.vue";
import { downloadFile } from '@/api/downloadFile';

export default {
    name: "f2-form-response-viewer",
    props: [
        "id",
        "form"
    ],
    components: { Form, FormResponseChanges, FormResponseNavigator },
    mounted() {
        //this.refresh();
        this.initFields();
        console.log(this.spreadsheet.rows);
    },
    data() {
        return {
            fullscreen: false,
            responseCount: 0,
            responses : [],
            fields    : (this.form.fields || []),
            field_keys: [],
            validInputTypes: ["button", "checkbox", "color", "date", "datetime-local", 
                              "email", "file", "hidden", "image", "month", "number", 
                              "password", "radio", "range", "reset", "search", "submit",
                               "tel", "text", "time", "url", "week"],
            spreadsheet: {
                rows : [],
                fields: []
            },
            selectedAction: "",
            responseActions: [
                { icon: 'pencil-alt', text: 'Edit',         value: 'edit'         },
                { icon: 'history',    text: 'View Changes', value: 'view_changes' },
                { icon: 'trash',      text: 'Delete',       value: 'delete'       }
            ]
        };
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        refresh() {
            this.$emit("refresh");
            this.initFields();
        },
        setFullscreen() {
            this.fullscreen = true;
            document.documentElement.requestFullscreen();
        },
        exitFullscreen() {
            this.fullscreen = false;
            if (document.fullscreenElement)
                document.exitFullscreen();
        },
        saveRowChange(row) {
            this.$api.post(
                `/api/forms/admin/save-form-response`,
                {
                    id      : this.currentResponse.id,
                    changed : row
                }
            ).then(({ data }) => {
                if (data.success)
                    this.$bvModal.msgBoxOk("Form response changes saved. Your changes were audited.").then(() => {
                        row.saved   = true;
                        row.changed = true;
                        Object.assign(this.responses[this.responseCount - 1], row);
                        this.selectedAction = "";
                    });
            });
        },
        initFields() {
            this.fields = this.form.fields;
            this.spreadsheet.fields = this.fields.map((field) => {
                this.field_keys.push(field.id.toString());
                return {
                    text     : field.label,
                    type     : (this.validInputTypes.includes(field.subtype)) ? field.subtype : "text"
                };
            });
            this.spreadsheet.fields.unshift({
                text     : "Date Submitted",
                type     : "datetime-local",
                disabled : true
            });
            this.spreadsheet.fields.unshift({
                text     : "Last Name",
                type     : "text",
                disabled : true
            });
            this.spreadsheet.fields.unshift({
                text     : "First Name",
                type     : "text",
                disabled : true
            });
            this.field_keys.unshift("date_submitted");
            this.field_keys.unshift("last_name");
            this.field_keys.unshift("first_name");
            this.spreadsheet.cols = this.spreadsheet.fields.length;
            this.getResponses();
        },
        getResponses() {
            delete this.spreadsheet.rows.slice(1);
            this.responseCount = 0;
            this.$api.get(
                `/api/forms/admin/${this.id}/responses`
            ).then(({ data }) => {
                this.responses = data.form_responses;
                if (this.responses.length > 0) this.responseCount = 1;
            });
        },
        actionChange(action) {
            if (action == "delete")
                this.askRemoveResponse(this.currentResponse);
            this.selectedAction = action;
        },
        askRemoveResponse(response) {
            this.$bvModal.msgBoxConfirm('Please confirm that you want to remove this response.', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then((confirmed) => {
                if (confirmed)
                    this.removeRow(response);
            });
        },
        removeRow(response) {
            this.$api.post(
                `/api/forms/admin/remove-form-response`,
                {
                    id : response.id
                }
            ).then(({ data }) => {
                if (data.success) response.removed = true;
            });
        },
        exportCSV () {
            downloadFile(
                `/api/forms/admin/export-csv/responses-${this.form.id}`,
                `${this.form.title.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.csv`
            );
        }
    },
    computed: {
        currentResponse() {
            return this.responses[this.responseCount - 1];
        }
    }
}
</script>