<template>
    <b-card class="text-center">
        <template v-if="responses.length > 0">
            <div style="display: inline-block;">
                <b-input-group>
                    <b-input-group-prepend>
                        <button
                            :disabled="value <= 1"
                            class="sc-btn pill mr-2"
                            @click="$emit('input', value - 1)"
                        >
                            <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                        </button>
                        <b-input
                            :value="value"
                            @input="(value) => $emit('input', value.trim())"
                            type="number"
                            :min="1"
                            :max="responses.length"
                            style="max-width: 75px;"
                        />
                    </b-input-group-prepend>
                    <b-input-group-text>
                        /
                    </b-input-group-text>
                    <b-input-group-text>
                        {{ responses.length }}
                    </b-input-group-text>
                    <b-select
                        :value="value"
                        @change="(value) => $emit('input', value)"
                    >
                        <b-select-option
                            v-for="(response, idx) in responses"
                            :key="idx"
                            :value="idx + 1"
                        >
                            {{ (response.person) ? response.person.first_name : "(Anonymous)" }}
                            {{ (response.person) ? response.person.last_name  : "" }}
                        </b-select-option>
                    </b-select>
                    <button
                        :disabled="value >= responses.length"
                        class="sc-btn pill ml-2"
                        @click="$emit('input', value + 1)"
                    >
                        <font-awesome-icon icon="chevron-right"></font-awesome-icon>
                    </button>
                </b-input-group>
            </div>
        </template>
        <template v-else>
            <em>There are no form responses to display.</em>
        </template>
    </b-card>
</template>
<script>
export default {
    props: ["responses", "value"]
}
</script>